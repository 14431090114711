'use client';

import { useResponsiveQuery } from 'atomic-layout';
import React, { useCallback, useEffect, useState } from 'react';

import Button, { ButtonProps } from '@/app/components/base/ui/Button';
import DrawerAccountOpening from '@/app/components/sections/OpenAccount/drawer';
import QRCode from '@/components/v3/QRCode';
import LINKS from '@/constants/links';
import { useAccountExperiment } from '@/hooks/useAccountExperiment';
import { GAsendEvent } from '@/utils/Analytics';
import { handleAppsflyerUtmUrl } from '@/utils/handleAppsflyerUtmUrl';

type TLinkOpenYourAccount = {
  className?: string;
  title?: string;
  description?: string;
  color?: ButtonProps['color'];
  size?: ButtonProps['size'];
  qrCodeLink?: string;
  label?: string;
  ctaConfig?: {
    categories: string;
    action: string;
    label: string;
  };
  children?: React.ReactNode;
  onClick?: () => void;
  sectionReference?: string;
};

const ButtonOpenYourAccount: React.FC<TLinkOpenYourAccount> = ({
  qrCodeLink = LINKS.APP_LINK_QR_CODE,
  className,
  color,
  size,
  label,
  ctaConfig,
  children,
  onClick,
  sectionReference,
  description,
  title,
}) => {
  const isMobile = useResponsiveQuery({ to: 'lg' });
  const [utmUrl, setUtmUrl] = useState(qrCodeLink);
  const [toggleDrawer, setToggleDrawer] = useState(false);
  const {
    shouldEnterWebAccountOpeningFlow,
    shouldEnterWebAccountOpeningFlowControl,
  } = useAccountExperiment();

  const updateUtmUrl = useCallback(
    () => setUtmUrl(handleAppsflyerUtmUrl(qrCodeLink)),
    [qrCodeLink],
  );

  useEffect(updateUtmUrl);

  const handleClickButton = () => {
    /* TODO: Não usamos mais CTAs config para o GA, devemos remover essas funções do código */
    ctaConfig &&
      GAsendEvent(ctaConfig.categories, ctaConfig.action, ctaConfig.label);

    (!isMobile || shouldEnterWebAccountOpeningFlow) &&
      setToggleDrawer(!toggleDrawer);
  };

  return (
    /* TODO: todas as const qrCodeLink são ajuste para atender a pagina de planos, devemos rever essa const */
    <>
      {shouldEnterWebAccountOpeningFlow ? (
        <DrawerAccountOpening
          isShowing={toggleDrawer}
          onToggle={() => setToggleDrawer(!toggleDrawer)}
        />
      ) : shouldEnterWebAccountOpeningFlowControl ? (
        <QRCode
          qrCodeLink={LINKS.APP_LINK_APPSFLYER_ACCOUNT_OPPENING_CONTROL}
          isShowing={toggleDrawer}
          onToggle={() => setToggleDrawer(!toggleDrawer)}
          description={description}
          title={title}
        />
      ) : (
        <QRCode
          qrCodeLink={qrCodeLink}
          isShowing={toggleDrawer}
          onToggle={() => setToggleDrawer(!toggleDrawer)}
          description={description}
          title={title}
        />
      )}

      {!shouldEnterWebAccountOpeningFlow && isMobile ? (
        <Button
          linkHref={utmUrl}
          label={label}
          size={size}
          color={color}
          sectionReference={sectionReference || 'Plans Cards'}
          onClick={() => {
            handleClickButton();
            onClick && onClick();
          }}
          className={`btn w-full min-w-full md:min-w-min ${className}`}
        >
          {children}
        </Button>
      ) : (
        <Button
          color={color}
          label={label}
          size={size}
          onClick={() => {
            handleClickButton();
            onClick && onClick();
          }}
          className={className}
          sectionReference={sectionReference || 'Plans Cards'}
        >
          {children}
        </Button>
      )}
    </>
  );
};

export default ButtonOpenYourAccount;
