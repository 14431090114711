'use client';

import { useContext, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import ButtonOpenYourAccount from '@/components/ButtonOpenYourAccount';
import { AmplitudeContext } from '@/contexts/amplitude';
/* eslint-disable tailwindcss/no-contradicting-classname */
import { FaqProps as FaqCMSProps } from '@/types/cms';

import Button, { ButtonProps } from '../../base/ui/Button';
import FaqItem from './FaqItem';

interface faqButton extends ButtonProps {
  qrCodeLink?: string;
}
export interface FaqProps extends Omit<FaqCMSProps, 'id' | 'buttons'> {
  buttonIsShowMobileAndTablet?: boolean;
  buttons: faqButton[];
  auxiliaryText?: string;
  analyticsSectionReference?: string;
  buttonToTop: boolean;
  faqBackground?: string;
  downloadApp?: boolean;
}

export function Faq({
  referId,
  title,
  description,
  questions,
  buttons,
  auxiliaryText,
  downloadApp,
  buttonToTop,
  faqBackground = 'bg-display-100',
  buttonIsShowMobileAndTablet = true,
}: FaqProps) {
  const [ref, inView] = useInView({ triggerOnce: true });
  const { analytics } = useContext(AmplitudeContext);

  useEffect(() => {
    if (inView) {
      analytics?.track({
        event_type: 'section viewed',
        event_properties: {
          name: 'section viewed',
          description:
            'Evento disparado quando o usuário visualiza uma sessão especifica da pagina',
          section_reference: title,
        },
      });
    }
  }, [analytics, inView]);
  return (
    <>
      <section
        id={referId}
        data-testid="faq-section"
        className={faqBackground}
        ref={ref}
      >
        <div className="flex flex-col gap-24 lg:gap-40 justify-center items-center py-40 lg:py-[120px] px-24 mx-auto md:max-w-[1280px]">
          <div className="flex flex-col lg:flex-row gap-24 lg:gap-40 h-max">
            <div className="flex relative lg:sticky lg:top-40 flex-col gap-4 lg:gap-8 justify-center items-start max-w-full lg:max-w-[435px] xl:max-w-[455px] h-min">
              <h2
                className="items-start font-display text-3xl leading-7 heading-4 lg:heading-3"
                dangerouslySetInnerHTML={{
                  __html: title,
                }}
              />

              {description && (
                <p
                  className="items-start paragraph-16 lg:paragraph-18"
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
                />
              )}

              {!!buttons?.length && (
                <div
                  className={`${
                    buttonIsShowMobileAndTablet
                      ? 'flex flex-col md:flex-row'
                      : 'hidden lg:flex flex-col lg:flex-row'
                  } gap-16 justify-center items-start w-full md:w-max`}
                >
                  {buttons?.map((props, index) =>
                    downloadApp ? (
                      <ButtonOpenYourAccount
                        key={index}
                        qrCodeLink={props.qrCodeLink}
                        {...props}
                        sectionReference={title}
                        className="md:w-[fit-content]"
                        label={props.label}
                      />
                    ) : (
                      <Button
                        key={`${props.label}-${index}`}
                        {...props}
                        sectionReference={title}
                        className="md:w-[fit-content]"
                      />
                    ),
                  )}
                </div>
              )}
            </div>

            <div className="flex relative flex-col gap-24 items-start">
              <ul>
                {questions.map(({ question, answer }, index) => {
                  return (
                    <FaqItem
                      key={index}
                      question={question}
                      answer={answer}
                      currentItemPosition={index + 1}
                      itemsLength={questions.length}
                      show
                    />
                  );
                })}
              </ul>
              <div className="flex flex-col md:flex-row gap-16 justify-center md:justify-between items-center md:items-stretch w-full">
                {buttonToTop && (
                  <Button
                    sectionReference={title}
                    label="Retornar ao topo da página"
                    color="secondary"
                    size="large"
                    iconName="arrow-up"
                    iconPosition="left"
                    linkTarget="_self"
                    linkHash="#"
                    uiStyleVariant="button"
                    className="w-full md:w-max btn-large"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {auxiliaryText && (
        <div className="flex justify-center items-center py-[53px] px-24 bg-display-100">
          <p
            className="m-auto max-w-screen-xl text-display-900 text-start paragraph-16 lg:paragraph-18"
            dangerouslySetInnerHTML={{
              __html: auxiliaryText,
            }}
          />
        </div>
      )}
    </>
  );
}
